import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  query,
  style,
  animate,
} from "@angular/animations";
import { SpinnerService } from "src/app/services/spinner.service";
import { AuthService } from "src/app/services/auth.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { MultilevelMenuService } from "ng-material-multilevel-menu";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver } from "@angular/cdk/layout";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  animations: [
    trigger("myAnimation", [
      transition(":enter", [
        style({ transform: "translateX(100%)", opacity: 0 }),
        animate(
          "600ms ease-in",
          style({ transform: "translateX(0%)", opacity: 1 })
        ),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit {

  caja = JSON.parse(sessionStorage.getItem("caja")) || null;
  showSpinner: boolean;
  selectedData = null;
  isSmallScreen: boolean;
  showHome = true;
  expandCollapseStatus: string = null;
  nombre: string = null;
  appitems: any[];

  // Menú para root / admin
  menuAdmin = [
    {
      label: "Home",
      link: "/admin/dashboard",
      icon: "home",
    },
    {
      label: "Usuarios",
      link: "/admin/usuarios",
      icon: "group",
    },
    {
      label: "Sedes",
      link: "/admin/sedes",
      icon: "tour",
    },
    {
      label: "Eventos",
      icon: "emoji_events",
      items: [
        {
          label: "Lista de Eventos",
          link: "/admin/eventos",
          icon: "emoji_events",
        },
        {
          label: "Juez-Eventos",
          link: "/admin/juez-eventos",
          icon: "balance",
        },
        {
          label: "Equipos-Eventos",
          link: "/admin/equipos-eventos",
          icon: "groups",
        }
      ]
    },
    {
      label: "Equipos",
      icon: "groups",
      items: [
        {
          label: "Lista",
          link: "/admin/equipos",
          icon: "view_list",
        },
        {
          label: "Categorias",
          link: "/admin/categorias-equipos",
          icon: "category",
        }
      ]
    },
    {
      label: "Integrantes",
      link: "/admin/integrantes",
      icon: "card_membership",
    },
    {
      label: "Conceptos",
      link: "/admin/conceptos",
      icon: "analytics",
    },
   /*  {
      label: "Penalizaciones",
      link: "/admin/penalizaciones",
      icon: "gavel",
    }, */
    {
      label: "Ejercicios",
      link: "/admin/ejercicios",
      icon: "sports_score",
    },
    {
      label: "Rutinas",
      link: "/admin/rutinas",
      icon: "sports",
    },
    /* {
      label: "Competencia",
      link: "/admin/competencia",
      icon: "military_tech",
    }, */
    {
      label: "Calificaciones",
      link: "/admin/calificaciones",
      icon: "checklist",
    },
    {
      label: "Resultados",
      link: "/admin/resultados",
      icon: "border_color",
    },
    /* {
      label: "Revision",
      link: "/admin/elegir-evaluacion",
      icon: "assignment_turned_in",
    } */
    /* {
      label: "Revision",
      icon: "assignment_turned_in",
      items:[
        {
          label: "Revision",
          link: "/admin/calificar",
          icon: "assignment_turned_in",
        },
        {
          label: "Historial",
          link: "/admin/historial-revision",
          icon: "grid_view",
        }
      ]
    }, */
  ];

  //  Menú para organizadores
  menuOrganizadores = [
    {
      label: "Home",
      link: "/admin/dashboard",
      icon: "home",
    },
    {
      label: "Usuarios",
      link: "/admin/usuarios",
      icon: "group",
    },
    {
      label: "Sedes",
      link: "/admin/sedes",
      icon: "tour",
    },
    {
      label: "Eventos",
      icon: "emoji_events",
      items: [
        {
          label: "Lista de Eventos",
          link: "/admin/eventos",
          icon: "emoji_events",
        },
        {
          label: "Juez-Eventos",
          link: "/admin/juez-eventos",
          icon: "balance",
        },
        {
          label: "Equipos-Eventos",
          link: "/admin/equipos-eventos",
          icon: "groups",
        }
      ]
    },
    {
      label: "Equipos",
      icon: "groups",
      items: [
        {
          label: "Lista",
          link: "/admin/equipos",
          icon: "view_list",
        },
        {
          label: "Categorias",
          link: "/admin/categorias-equipos",
          icon: "category",
        }
      ]
    },
    {
      label: "Integrantes",
      link: "/admin/integrantes",
      icon: "card_membership",
    },
    {
      label: "Conceptos",
      link: "/admin/conceptos",
      icon: "analytics",
    },
  ];

  // Menú para capitanas
  menuCapitanas = [
    {
      label: "Home",
      link: "/admin/dashboard",
      icon: "home",
    },
    /* {
      label: "Equipos",
      icon: "groups",
      items: [
        {
          label: "Lista",
          link: "/admin/equipos",
          icon: "view_list",
        },
        {
          label: "Categorias",
          link: "/admin/categorias-equipos",
          icon: "category",
        }
      ]
    }, */
    {
      label: "Integrantes",
      link: "/admin/integrantes",
      icon: "card_membership",
    },
    {
      label: "Ejercicios",
      link: "/admin/ejercicios",
      icon: "sports_score",
    },
    {
      label: "Rutinas",
      link: "/admin/rutinas",
      icon: "sports",
    },
    {
      label: "Resultados",
      link: "/admin/resultados",
      icon: "border_color",
    },
  ];

  // Menú para entrenadores
  menuEntrenadores = [
    {
      label: "Home",
      link: "/admin/dashboard",
      icon: "home",
    },
    /* {
      label: "Equipos",
      icon: "groups",
      items: [
        {
          label: "Lista",
          link: "/admin/equipos",
          icon: "view_list",
        },
        {
          label: "Categorias",
          link: "/admin/categorias-equipos",
          icon: "category",
        }
      ]
    }, */
    /* {
      label: "Integrantes",
      link: "/admin/integrantes",
      icon: "card_membership",
    }, */
    {
      label: "Ejercicios",
      link: "/admin/ejercicios",
      icon: "sports_score",
    },
    {
      label: "Rutinas",
      link: "/admin/rutinas",
      icon: "sports",
    },
    {
      label: "Resultados",
      link: "/admin/resultados",
      icon: "border_color",
    },
    {
      label: "Actividades",
      link: "/admin/actividades",
      icon: "assignment_turned_in",
    },
  ];

  // Menú para jueces
  menuJueces = [
    {
      label: "Home",
      link: "/admin/dashboard",
      icon: "home",
    },
    {
      label: "Rutinas",
      link: "/admin/rutinas",
      icon: "sports",
    },
    /* {
      label: "Competencia",
      link: "/admin/competencia",
      icon: "military_tech",
    }, */
    {
      label: "Calificaciones",
      link: "/admin/elegir-competencia",
      icon: "checklist",
    },
    {
      label: "Resultados",
      link: "/admin/resultados",
      icon: "border_color",
    }
  ];

  // Menú para jueces de revisión de video
  menuJuecesRevision = [
    {
      label: "Home",
      link: "/admin/dashboard",
      icon: "home",
    },
    {
      label: "Sedes",
      link: "/admin/sedes",
      icon: "tour",
    },
    {
      label: "Eventos",
      icon: "emoji_events",
      items: [
        {
          label: "Lista de eventos",
          link: "/admin/eventos",
          icon: "emoji_events",
        }
      ]
    },
    {
      label: "Equipos",
      icon: "groups",
      items: [
        {
          label: "Lista",
          link: "/admin/equipos",
          icon: "view_list",
        }
      ]
    },
    {
      label: "Resultados",
      link: "/admin/resultados",
      icon: "border_color",
    },
    /* {
      label: "Revision",
      link: "/admin/elegir-evaluacion",
      icon: "assignment_turned_in",
    } */
  ];

  // Menú para locutor
  menuLocutores = [
    {
      label: "Home",
      link: "/admin/dashboard",
      icon: "home",
    },
    /* {
      label: "Usuarios",
      link: "/admin/usuarios",
      icon: "group",
    }, */
    {
      label: "Sedes",
      link: "/admin/sedes",
      icon: "tour",
    },
    {
      label: "Eventos",
      icon: "emoji_events",
      items: [
        {
          label: "Equipos-Eventos",
          link: "/admin/equipos-eventos",
          icon: "groups",
        }
      ]
    },
    {
      label: "Informacion",
      icon: "info",
      items: [
        {
          label: "Jueces",
          link: "/admin/lista-juecez",
          icon: "gavel",
        },
        {
          label: "Entrenadores",
          link: "/admin/lista-entrenadores",
          icon: "directions_run",
        },
/*         {
          label: "Equipos",
          link: "/admin/equipos-eventos",
          icon: "groups",
        } */
      ]
    },
    {
      label: "Rutinas",
      link: "/admin/rutinas",
      icon: "sports",
    },
    {
      label: "Resultados",
      link: "/admin/resultados",
      icon: "border_color",
    },
  ];

  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    collapseOnSelect: true,
    highlightOnSelect: true,
  };

  constructor(
    private multilevelMenuService: MultilevelMenuService,
    public spinnerService: SpinnerService,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver
  ) {
    if ( this.authService.hasRole("ROLE_ADMIN") ) {
      this.appitems = this.menuAdmin;
      console.log("Muestro menu root");
    } else if ( this.authService.hasRole("ROLE_ORGANIZADOR") ) {
      console.log("Muestro menu organizador");
      this.appitems = this.menuOrganizadores;
    } else if ( this.authService.hasRole("ROLE_CAPITANAS") ) {
      console.log("Muestro menu capitanas");
      this.appitems = this.menuCapitanas;
    } else if ( this.authService.hasRole("ROLE_ENTRENADORES") ) {
      console.log("Muestro menu entrenadores");
      this.appitems = this.menuEntrenadores;
    } else if ( this.authService.hasRole("ROLE_JUECES") || this.authService.hasRole("ROLE_JUEZ_CENTRAL") ) {
      console.log("Muestro menu jueces");
      this.appitems = this.menuJueces;
    } else if ( this.authService.hasRole("ROLE_JUEZ_REVISION") ) {
      console.log("Muestro menu juez revision");
      this.appitems = this.menuJuecesRevision;
    } else if ( this.authService.hasRole("ROLE_LOCUTOR") ) {
      console.log("Muestro menu locutor");
      this.appitems = this.menuLocutores;
    }
  }

  ngOnInit() {
    this.isSmallScreen =
      this.breakpointObserver.isMatched("(min-width: 400px)");
  }

  selectedItem(selectedData) {
    this.selectedData = selectedData;
  }


  setExpandCollapseStatus(type) {
    this.multilevelMenuService.setMenuExapandCollpaseStatus(type);
  }

  logout(): void {
    const email = this.authService.usuario.email;
    this.authService.logout();
    Swal.fire(
      "Logout",
      `Adiós ${email}, has cerrado sesión con éxito!`,
      "success"
    );
    this.router.navigate(["/admin"]);
  }


}
