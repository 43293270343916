<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon color="primary">close</mat-icon>
</button>

<h1 class="dialog-title" mat-dialog-title>Patrocinador</h1>

<br />

<mat-divider></mat-divider>

<div *ngIf="colorFondo == '#ffffff'">
    <img
        class="bg-light"
        [src]="rutaImagen"
        style="width: 100%; cursor: pointer"
    />
</div>

<div *ngIf="colorFondo == '#000000'">
    <img
        class="bg-dark"
        [src]="rutaImagen"
        style="width: 100%; cursor: pointer"
    />
</div>
