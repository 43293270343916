<div class="error sec-padding">
    <div class="container">
       <div class="page404">
          Oops! Error 404
       </div>
       <div class="not-found">
          Página no encontrada
       </div>
       <div class="error_description">
         <p>Parece que no podemos encontrar la página que está buscando</p>
       </div>
       <a [routerLink]="['/']" mat-raised-button color="primary">REGRESAR</a>
    </div>
  </div>
