<!-- <div *ngIf="delayFinish" class="banner">
  <div>
    <img (click)="abrirImagen('assets/images/banner/rancho_la_sierrita_qh.png', '#ffffff')" class="img-banner bg-light" src="assets/images/banner/rancho_la_sierrita_qh.png">
    <img (click)="abrirImagen('assets/images/banner/alba.png', '#ffffff')" class="img-banner bg-light" src="assets/images/banner/alba.png">
    <img (click)="abrirImagen('assets/images/banner/all_horses.png', '#ffffff')" class="img-banner" src="assets/images/banner/all_horses.png">
    <img (click)="abrirImagen('assets/images/banner/tierra_mexicana.png', '#ffffff')" class="img-banner bg-light" src="assets/images/banner/tierra_mexicana.png">
    <img (click)="abrirImagen('assets/images/banner/rancho_las_amazonas.png', '#000000')" class="img-banner bg-dark" src="assets/images/banner/rancho_las_amazonas.png">
    <img (click)="abrirImagen('assets/images/banner/hrobles.jpeg', '#ffffff')" class="img-banner" src="assets/images/banner/hrobles.jpeg">
    <img (click)="abrirImagen('assets/images/banner/black_horses.jpg', '#ffffff')" class="img-banner" src="assets/images/banner/black_horses.jpg">
  </div>
</div> -->

<router-outlet></router-outlet>

