import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ActividadesModule } from './components/actividades/actividades.module';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'admin' },
  {
    path: 'admin/login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin/dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'admin/patrocinadores',
    loadChildren: () =>
      import('./components/patrocinadores/patrocinadores.module').then(
        (m) => m.PatrocinadoresModule
      ),
  },
  {
    path: 'admin/usuario',
    loadChildren: () => import('./components/usuario/usuario.module').then((m) => m.UsuarioModule),
  },
  {
    path: 'admin/eventos',
    loadChildren: () =>
      import('./components/eventos/eventos.module').then(
        (m) => m.EventosModule
      ),
  },
  {
    path: 'admin/llamadas',
    loadChildren: () =>
      import('./components/llamadas/llamadas.module').then(
        (m) => m.LlamadasModule
      ),
  },
  {
    path: 'admin/giro-negocio',
    loadChildren: () =>
      import('./components/giro-negocio/giro-negocio.module').then(
        (m) => m.GiroNegocioModule
      ),
  },
  {
    path: 'admin/publicaciones',
    loadChildren: () =>
      import('./components/publicaciones/publicaciones.module').then(
        (m) => m.PublicacionesModule
      )
  },
  {
    path: 'admin/publicaciones/:key',
    loadChildren: () =>
      import('./components/publicaciones/publicaciones.module').then(
        (m) => m.PublicacionesModule
      )
  },
  {
    path: 'admin/reporte-llamadas',
      loadChildren: () => import('./components/reporte-llamadas/reporte-llamadas.module').then(
        (m) => m.ReporteLlamadasModule
      )
  },
  {
    path: 'admin/calificar',
      loadChildren:() => import('./components/calificar/calificar.module').then(
        (m) => m.CalificarModule
      )
  },
  {
    path: 'admin/usuarios',
      loadChildren:() => import('./components/usuarios/usuarios.module').then(
        (m) => m.UsuariosModule
      )
  },
  {
    path: 'admin/sedes',
      loadChildren:() => import('./components/sedes/sedes.module').then(
        (m) => m.SedesModule
      )
  },
  {
    path: 'admin/equipos',
      loadChildren:() => import('./components/equipos/equipos.module').then(
        (m) => m.EquiposModule
      )
  },
  {
    path: 'admin/integrantes',
      loadChildren:() => import('./components/integrantes/integrantes.module').then(
        (m) => m.IntegrantesModule
      )
  },
  {
    path: 'admin/conceptos',
      loadChildren:() => import('./components/conceptos/conceptos.module').then(
        (m) => m.ConceptosModule
      )
  },
  {
    path: 'admin/ejercicios',
      loadChildren:() => import('./components/ejercicios/ejercicios.module').then(
        (m) => m.EjerciciosModule
      )
  },
  {
    path: 'admin/rutinas',
      loadChildren:() => import('./components/rutinas/rutinas.module').then(
        (m) => m.RutinasModule
      )
  },
  {
    path: 'admin/competencia',
      loadChildren:() => import('./components/competencia/competencia.module').then(
        (m) => m.CompetenciaModule
      )
  },
  {
    path: 'admin/calificaciones',
      loadChildren:() => import('./components/calificaciones/calificaciones.module').then(
        (m) => m.CalificacionesModule
      )
  },
  {
    path: 'admin/resultados',
      loadChildren:() => import('./components/resultados/resultados.module').then(
        (m) => m.ResultadosModule
      )
  },
  {
    path: 'admin/categorias-equipos',
      loadChildren:() => import('./components/categorias-equipos/categorias-equipos.module').then(
        (m) => m.CategoriasEquiposModule
      )
  },
  {
    path: 'admin/historial-revision',
      loadChildren:() => import('./components/historial-revision/historial-revision.module').then(
        (m) => m.HistorialRevisionModule
      )
  },
  {
    path: 'admin/juez-eventos',
      loadChildren:() => import('./components/juez-eventos/juez-eventos.module').then(
        (m) => m.JuezEventosModule
      )
  },
  {
    path: 'admin/equipos-eventos',
      loadChildren:() => import('./components/equipos-eventos/equipos-eventos.module').then(
        (m) => m.EquiposEventosModule
      )
  },
  {
    path: 'admin/elegir-competencia',
      loadChildren:() => import('./components/elegir-competencia/elegir-competencia.module').then(
        (m) => m.ElegirCompetenciaModule
      )
  },
  {
    path: 'admin/elegir-evaluacion',
      loadChildren:() => import('./components/elegir-evaluacion/elegir-evaluacion.module').then(
        (m) => m.ElegirEvaluacionModule
      )
  },
  {
    path: 'admin/puntas',
      loadChildren:() => import('./components/puntas/puntas.module').then(
        (m) => m.PuntasModule
      )
  },
  {
    path: 'admin/lista-juecez',
      loadChildren:() => import('./components/lista-juecez/lista-juecez.module').then(
        (m) => m.ListaJuecezModule
      )
  },
  {
    path: 'admin/lista-entrenadores',
      loadChildren:() => import('./components/lista-entrenadores/lista-entrenadores.module').then(
        (m) => m.ListaEntrenadoresModule
      )
  },
  {
    path: 'admin/creatividad',
      loadChildren:() => import('./components/creatividad/creatividad.module').then(
        (m) => m.CreatividadModule
      )
  },
  {
    path: 'admin/actividades',
      loadChildren:() => import('./components/actividades/actividades.module').then(
        (m) => m.ActividadesModule
      )
  },
  {
    path: 'admin/revision-video',
    loadChildren: () => import('./components/revision-video/revision-video.module').then(
      (m) => m.RevisionVideoModule
    )
  },
  {
    path: 'admin/penalizaciones',
    loadChildren: () => import('./components/penalizaciones/penalizaciones.module').then(
      (m) => m.PenalizacionesModule
    )
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
  // {
  //   path: "**",
  //   redirectTo: "home",
  //   pathMatch: "full",
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
