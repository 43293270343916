<!-- Navbar -->
<mat-toolbar color="primary">

  <!-- Botón para abrir menú lateral -->
  <button
    (click)="sidenav.toggle()"
    aria-label="menu"
    class="menu"
    mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>

  <!-- Botón de usuario para abrir lista de opciones -->
  <button mat-button [matMenuTriggerFor]="userMenu">
    <mat-icon>person</mat-icon>
    <span fxShow fxHide.xs>
      {{ authService.usuario.nombre }}
    </span>
  </button>

  <!-- Lista de opciones -->
  <mat-menu
    #userMenu="matMenu"
    xPosition="before"
    yPosition="above"
    [overlapTrigger]="false">
    <a mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Cerrar sesión</span>
    </a>
  </mat-menu>

</mat-toolbar>

<!-- Menú lateral -->
<mat-sidenav-container>
  <mat-sidenav [disableClose]="false" mode="over" #sidenav>
    <mat-nav-list>

      <!-- Datos del usuario -->
      <div class="profile-card">
        <img src="assets/images/user.png" alt="Usuario"/>
        <div class="header">
          <h4>{{ authService.usuario.nombre }}</h4>
          <h4>{{ authService.usuario.email }}</h4>
        </div>
      </div>

      <!-- Separador -->
      <mat-divider></mat-divider>

      <!-- Lista de opciones de la barra lateral -->
      <ng-material-multilevel-menu
        [configuration]="config"
        [items]="appitems"
        (selectedItem)="selectedItem($event)">
      </ng-material-multilevel-menu>

    </mat-nav-list>
  </mat-sidenav>

  <!-- Contenido de la página -->
  <mat-sidenav-content>
    <div class="progress-bar-container">
      <mat-progress-bar
        color="accent"
        mode="indeterminate"
        *ngIf="spinnerService.visibility | async">
      </mat-progress-bar>
    </div>
    <div [@myAnimation]="o.isActivated ? o.activatedRoute : ''">
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
