import { environment } from "src/environments/environment";

export let BASE_ENDPOINT = '';
export let BASE_ENDPOINT_AUTH = '';

if ( !environment.production ) {
    // BASE_ENDPOINT = 'http://159.65.223.115:8099/api';
    // BASE_ENDPOINT_AUTH = 'http://159.65.223.115:8099/api/security/oauth/token';

    BASE_ENDPOINT = 'http://137.184.216.240:8090/api';
    BASE_ENDPOINT_AUTH = 'http://137.184.216.240:8090/api/security/oauth/token';
}

if ( environment.production ) {
    BASE_ENDPOINT = 'http://159.65.223.115:8099/api';
    BASE_ENDPOINT_AUTH = 'http://159.65.223.115:8099/api/security/oauth/token';
}
