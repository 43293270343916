import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patrocinador-details',
  templateUrl: './patrocinador-details.component.html',
  styleUrls: ['./patrocinador-details.component.scss']
})
export class PatrocinadorDetailsComponent implements OnInit {

  rutaImagen: string;
  colorFondo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modalRef: MatDialogRef<PatrocinadorDetailsComponent>,
  ) { }

  ngOnInit(): void {
    this.rutaImagen = this.data.rutaImagen;
    this.colorFondo = this.data.colorFondo;
  }

  cancelar(): void {
    this.modalRef.close();
  }

}
