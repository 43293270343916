
export class Usuario {
  key: string;
  password: string;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  direccion: string;
  email: string;
  roles: string[] = [];
}
