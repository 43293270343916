import { trigger, transition, query, style, animate } from '@angular/animations';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PatrocinadorDetailsComponent } from './patrocinador-details/patrocinador-details.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'app-eventos';
  delayFinish: boolean = false;

  constructor(
    public dialog: MatDialog
  ) { }

  ngAfterViewInit(): void {
    this.init();
  }

  abrirImagen( rutaImagen: string, colorFondo: string ) {
    this.dialog.open(PatrocinadorDetailsComponent, {
      width: '900px',
      data: { rutaImagen: rutaImagen, colorFondo: colorFondo },
    });
  }

  init(): void {
    setTimeout(() => {
      this.delayFinish = true;
    }, 2000);
  }

}
