import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from '../auth/usuario';
import { BASE_ENDPOINT_AUTH } from '../config/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _usuario: Usuario;
  private _token: string;
  private _refreshToken: string;
  public redirectUrl: string;

  constructor(private http: HttpClient) { }

  public get usuario(): Usuario {
    if (this._usuario != null) {
      return this._usuario;
    } else if (this._usuario == null && sessionStorage.getItem('usuario') != null) {
      this._usuario = JSON.parse(sessionStorage.getItem('usuario')) as Usuario;
      return this._usuario;
    }
    return new Usuario();
  }

  public get token(): string {
    if (this._token != null) {
      return this._token;
    } else if (this._token == null && sessionStorage.getItem('token') != null) {
      this._token = sessionStorage.getItem('token');
      return this._token;
    }
    return null;
  }

  login(usuario: Usuario): Observable<any> {

    const urlEndpoint  = BASE_ENDPOINT_AUTH;

    const credenciales = btoa('frontendapp' + ':' + '12345');

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + credenciales
    });

    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', usuario.email);
    params.set('password', usuario.password);
    // console.log(params.toString());
    return this.http.post<any>(urlEndpoint, params.toString(), { headers: httpHeaders });
  }

  guardarUsuario(accessToken: string): void {
    console.log(accessToken)
    const payload = this.obtenerDatosToken(accessToken);
    this._usuario = new Usuario();
    // console.log(this._usuario);
    this._usuario.key = payload.key;
    this._usuario.nombre = payload.nombre;
    this._usuario.apellidoPaterno = payload.apellidoPaterno;
    this._usuario.apellidoPaterno = payload.apellidoPaterno;
    this._usuario.email = payload.email;
    this._usuario.roles = payload.authorities;
    console.log(payload.authorities);
    sessionStorage.setItem('usuario', JSON.stringify(this._usuario));
  }

  guardarToken(accessToken: string): void {
    this._token = accessToken;
    sessionStorage.setItem('token', accessToken);
  }

  obtenerDatosToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(atob(accessToken.split('.')[1]));
    }
    return null;
  }

  isAuthenticated(): boolean {
    const payload = this.obtenerDatosToken(this.token);
    if (payload != null && payload.email && payload.email.length > 0) {
      return true;
    }
    return false;
  }

  hasRole(role: string): boolean {
    if (this.usuario.roles.includes(role)) {
      return true;
    }
    return false;
  }

  logout(): void {
    this._token = null;
    this._usuario = null;
    sessionStorage.clear();
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('usuario');
  }

  actualizarToken(refreshToken: string): Observable<any> {
    const urlEndPoint = BASE_ENDPOINT_AUTH;
    const credenciales = btoa('frontendapp' + ':' + '12345');

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + credenciales
    });

   // console.log('actualizarToken: '+refreshToken);
    const params = new URLSearchParams();
    params.set('grant_type', 'refresh_token');
    params.set('refresh_token', refreshToken);
    return this.http.post<any>(urlEndPoint, params.toString(), { headers: httpHeaders });
  }

  guardarRefreshToken(refreshToken: string): void {
    this._refreshToken = refreshToken;
    sessionStorage.setItem('refresh_Token', refreshToken);
  }

  public get refreshToken(): string {
    if (this._refreshToken != null) {
      return this._refreshToken;
    } else if (this._refreshToken == null && sessionStorage.getItem('refresh_Token') != null) {
      this._refreshToken = sessionStorage.getItem('refresh_Token');
      return this._refreshToken;
    }
    return null;
  }
}
